import React from 'react'
import { Typography, Box, Button, Container} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import {logOut} from '../../../services/auth'

/*

	*/
let color = "#FFCF10"
const Submitted = ({logout = () => {}}) => {
	return <>
		<div style={{
			height: '1000px',
			position: 'fixed',
			top: 0,
			paddingTop: '7em',
			zIndex: 2,
			backgroundColor: 'white'
		}} >
			<Container>
				<Box style={{
					textAlign: 'center',
					marginBottom: '3em'
				}}>
					<Typography variant="h5" style={{
					}}>Verification in Process</Typography>
				</Box>
				<div style={{
					textAlign: 'center'
				}}>
					<FontAwesomeIcon icon={faClock} style={{
						fontSize: '9em',
						color: color,
					}} />
				</div>


				<Box marginTop={5} marginBottom={5}>
					<Typography variant="body2" >
						Ang iyong registration ay kasalukuyang nire-review. Ikaw ay
						tatawagan ng Spark Express team kapag nakumpirma na ang iyong registration.
					</Typography>
				</Box>

				<Button onClick = {logOut} fullWidth style={{ backgroundColor: "#FFCF10" }}>
					LogOut
				</Button>
			</Container>
		</div>

	</>
}


export default Submitted